import React from "react"
import styled from "styled-components"
import logo from "./rs-logo.png"
import { useContext, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import DispatchContext from "./DispatchContext"
import StateContext from "./StateContext"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import LanguageSelector from "./LanguageSelector"
import ModernSearch from "./ModernSearch"

const Logo = styled.img`
  width: 168px;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
  height: 60px;
`

const NavLink = styled.div`
  font-family: Montserrat, sans-serif;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
`

const ActiveNavLink = styled(NavLink)`
  color: var(--gt-3-i-com-apple, #355c8b);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -22px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--gt-3-i-com-apple, #355c8b);
  }
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 80px;
  background-color: var(--gt-3-i-com-mercury, #e4e4e4);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
  font-size: 15px;
  color: var(--gt-3-i-com-black, #000001);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 133%;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding: 20px;
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 991px) {
    display: none;  // Default to none on mobile unless menu is open
    flex-basis: 100%;
    justify-content: space-between;
    margin-top: 20px;
    flex-direction: column;  // Stack the links vertically in the mobile menu
  }
`

const HamburgerIcon = styled.div`
  width: 40px;   // Adjust the width as needed
  height: 30px;  // Adjust the height as needed
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 991px) {
    display: flex;
  }

  div {
    width: 100%;
    height: 4px;  // Adjust thickness of the hamburger lines
    background-color: #000;  // Change color if needed
    border-radius: 2px;
    transition: all 0.3s linear;
  }
`

const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`

const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  &:hover .dropdown-content {
    display: block;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
`

function MainHeader(props) {
  const appDispatch = useContext(DispatchContext)
  const appState = useContext(StateContext)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 991)

  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }

  function handleSubmit(e) {
    appDispatch({ type: "logout" })
    appDispatch({ type: "addFlashMessages", value: "You have successfully logged out" })
  }

  function handleSearchIcon(e) {
    e.preventDefault()
    appDispatch({ type: "openSearch" })
  }

  function goToBuyEquipment() {
    navigate(`/`)
    toggleMenu()
  }

  function goToService() {
    navigate(`/services`)
  }

  function goToContact() {
    navigate(`/contact`)
  }

  function goToAbout() {
    navigate(`/about`)
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const searchStyle = isMobile ? 
    { height: '40px', marginTop: '0px', width: '100%' } : 
    { height: '0px', marginTop: '-75px', width: '100%' }

  return (
    <Header>
      <HamburgerIcon onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </HamburgerIcon>
      
      <Logo onClick={goToBuyEquipment} src={logo} alt="Logo" />
      
      {!menuOpen && (
        <div style={searchStyle}>
          <ModernSearch />
        </div>
      )}
      
      <Nav style={{ display: !isMobile ? 'flex' : menuOpen ? 'flex' : 'none' }}>
        {props.active === "0" ? (
          <ActiveNavLink onClick={goToBuyEquipment}>
            {t("Marketplace")}
          </ActiveNavLink>
        ) : (
          <NavLink onClick={goToBuyEquipment}>
            {t("Marketplace")}
          </NavLink>
        )}
        
        {props.active === "1" ? (
          <ActiveNavLink>
            <Dropdown>
              <div onClick={goToService}>
                {t("Service")}
              </div>
              <div className="dropdown-content">
                <Link to="/usedEquipment">{t("Used Equipment")}</Link>
                <Link to="/services/fab_support">{t("Fab Support")}</Link>
                <Link to="/services/circuitedit">{t("Circuit Edit")}</Link>
                <Link to="/services/tem">{t("TEM Sample Preparation")}</Link>
              </div>
            </Dropdown>
          </ActiveNavLink>
        ) : (
          <NavLink>
            <Dropdown>
              <div onClick={goToService}>
                {t("Service")}
              </div>
              <div className="dropdown-content">
                <Link to="/usedEquipment">{t("Used Equipment")}</Link>
                <Link to="/services/fab_support">{t("Fab Support")}</Link>
                <Link to="/services/circuitedit">{t("Circuit Edit")}</Link>
                <Link to="/services/tem">{t("TEM Sample Preparation")}</Link>
              </div>
            </Dropdown>
          </NavLink>
        )}
        
        {props.active === "2" ? (
          <ActiveNavLink onClick={goToAbout}>
            {t("About")}
          </ActiveNavLink>
        ) : (
          <NavLink onClick={goToAbout}>
            {t("About")}
          </NavLink>
        )}
        
        {props.active === "3" ? (
          <ActiveNavLink onClick={goToContact}>
            {t("Contact")}
          </ActiveNavLink>
        ) : (
          <NavLink onClick={goToContact}>
            {t("Contact")}
          </NavLink>
        )}
        
        <LanguageWrapper>
          <LanguageSelector changeLanguage={changeLanguage} />
        </LanguageWrapper>
      </Nav>
    </Header>
  )
}

export default MainHeader