import React, { useState, useRef, useEffect } from 'react';
import lan_image from './language-11-16.png';
import styled from 'styled-components';

const LanguageContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-block;
`;

const LanguageIcon = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);
  z-index: 1000;
  min-width: 80px;
  margin-top: 5px;
`;

const OptionItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  display: block;
  text-align: left;
  
  &:hover {
    background-color: #f5f5f5;
  }
`;

const LanguageSelector = ({ changeLanguage }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  
  const options = [
    { value: 'ch', label: '简体' },
    { value: 'tw', label: '正體' },
    { value: 'en', label: 'en' },
    { value: 'jp', label: '日本語' }
  ];
  
  const handleSelect = (value) => {
    changeLanguage(value);
    setShowDropdown(false);
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return (
    <LanguageContainer 
      ref={dropdownRef}
      onMouseEnter={() => setShowDropdown(true)}
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <LanguageIcon>
        <img src={lan_image} alt="Language" />
      </LanguageIcon>
      
      {showDropdown && (
        <DropdownMenu>
          {options.map((option) => (
            <OptionItem
              key={option.value}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </OptionItem>
          ))}
        </DropdownMenu>
      )}
    </LanguageContainer>
  );
};

export default LanguageSelector;