import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const ProductCarousel = ({ images = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 });
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const carouselRef = useRef(null);

  const sampleImages = [
    '/api/placeholder/600/400',
    '/api/placeholder/600/400',
    '/api/placeholder/600/400',
    '/api/placeholder/600/400',
    '/api/placeholder/600/400',
  ];

  const displayImages = images.length > 0 ? images : sampleImages;
  
  // Maximum number of dots to display
  const MAX_VISIBLE_DOTS = 7;

  useEffect(() => {
    let interval;
    if (isPlaying && !showMagnifier && !isHovering) {
      interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            setCurrentIndex((prevIndex) =>
              prevIndex === displayImages.length - 1 ? 0 : prevIndex + 1
            );
            return 0;
          }
          return prev + 2;
        });
      }, 50);
    }
    return () => clearInterval(interval);
  }, [isPlaying, showMagnifier, isHovering, displayImages.length]);

  const handlePrevious = () => {
    setIsPlaying(false);
    setProgress(0);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? displayImages.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIsPlaying(false);
    setProgress(0);
    setCurrentIndex((prevIndex) =>
      prevIndex === displayImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDotClick = (index) => {
    setIsPlaying(false);
    setProgress(0);
    setCurrentIndex(index);
  };

  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 0.5, 3));
    if (zoomLevel === 1) {
      setImagePosition({ x: 0, y: 0 });
    }
  };

  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 0.5, 1));
    if (zoomLevel <= 1.5) {
      setImagePosition({ x: 0, y: 0 });
    }
  };

  const handleFullScreen = () => {
    if (!isFullScreen) {
      if (carouselRef.current.requestFullscreen) {
        carouselRef.current.requestFullscreen();
      } else if (carouselRef.current.webkitRequestFullscreen) {
        carouselRef.current.webkitRequestFullscreen();
      } else if (carouselRef.current.msRequestFullscreen) {
        carouselRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  // Calculate which dots to show based on current index
  const getVisibleDots = () => {
    if (displayImages.length <= MAX_VISIBLE_DOTS) {
      // If we have fewer images than MAX_VISIBLE_DOTS, show all dots
      return displayImages.map((_, index) => index);
    }

    // Calculate how many dots to show on each side of the current dot
    const sideDotsCount = Math.floor((MAX_VISIBLE_DOTS - 1) / 2);
    
    let startDot = Math.max(0, currentIndex - sideDotsCount);
    let endDot = Math.min(displayImages.length - 1, currentIndex + sideDotsCount);
    
    // Adjust if we're at the beginning or end
    if (startDot === 0) {
      endDot = Math.min(displayImages.length - 1, MAX_VISIBLE_DOTS - 1);
    } else if (endDot === displayImages.length - 1) {
      startDot = Math.max(0, displayImages.length - MAX_VISIBLE_DOTS);
    }
    
    return Array.from({ length: endDot - startDot + 1 }, (_, i) => startDot + i);
  };

  const visibleDots = getVisibleDots();
  const showStartEllipsis = visibleDots[0] > 0;
  const showEndEllipsis = visibleDots[visibleDots.length - 1] < displayImages.length - 1;

  return (
    <Box
      ref={carouselRef}
      sx={{
        position: 'relative',
        width: '100%',
        maxWidth: isFullScreen ? '100vw' : 600,
        maxHeight: isFullScreen ? '100vh' : 'auto',
        aspectRatio: '4/3',
        bgcolor: 'grey.100',
        borderRadius: 2,
        overflow: 'hidden',
        transition: 'all 0.3s ease',
      }}
    >
      {/* Main Image */}
      <Box
        component="img"
        src={displayImages[currentIndex]}
        alt={`Product image ${currentIndex + 1}`}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => {
          setIsHovering(false);
          if (zoomLevel === 1) {
            setImagePosition({ x: 0, y: 0 });
          }
        }}
        onMouseDown={(e) => {
          if (zoomLevel > 1) {
            setIsDragging(true);
            setDragStart({ x: e.clientX - imagePosition.x, y: e.clientY - imagePosition.y });
          }
        }}
        onMouseUp={() => {
          setIsDragging(false);
        }}
        onMouseMove={(e) => {
          if (isDragging && zoomLevel > 1) {
            const newX = e.clientX - dragStart.x;
            const newY = e.clientY - dragStart.y;
            
            // Calculate boundaries based on zoom level
            const maxX = (zoomLevel - 1) * 300; // Adjust based on image size
            const maxY = (zoomLevel - 1) * 200;
            
            // Constrain movement within boundaries
            const constrainedX = Math.min(Math.max(newX, -maxX), maxX);
            const constrainedY = Math.min(Math.max(newY, -maxY), maxY);
            
            setImagePosition({ 
              x: constrainedX,
              y: constrainedY
            });
          }
        }}
        onMouseLeave={() => {
          setIsDragging(false);
          setIsHovering(false);
        }}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          transform: `scale(${zoomLevel}) translate(${imagePosition.x}px, ${imagePosition.y}px)`,
          transition: zoomLevel === 1 ? 'transform 0.3s ease' : 'none',
          cursor: zoomLevel > 1 ? (isDragging ? 'grabbing' : 'grab') : 'default',
        }}
      />

      {/* Zoom In/Out and Full Screen Buttons */}
      <Box
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          display: 'flex',
          gap: 1,
        }}
      >
        <IconButton
          onClick={handleZoomIn}
          sx={{
            bgcolor: 'rgba(245, 245, 245, 0.95)',
            '&:hover': {
              bgcolor: 'rgba(230, 230, 230, 1)',
            },
            width: 40,
            height: 40,
            borderRadius: '50%',
            boxShadow: 1,
          }}
        >
          <ZoomInIcon sx={{ color: 'rgba(100, 100, 100, 0.8)' }} />
        </IconButton>
        <IconButton
          onClick={handleZoomOut}
          sx={{
            bgcolor: 'rgba(245, 245, 245, 0.95)',
            '&:hover': {
              bgcolor: 'rgba(230, 230, 230, 1)',
            },
            width: 40,
            height: 40,
            borderRadius: '50%',
            boxShadow: 1,
          }}
        >
          <ZoomOutIcon sx={{ color: 'rgba(100, 100, 100, 0.8)' }} />
        </IconButton>
        <IconButton
          onClick={handleFullScreen}
          sx={{
            bgcolor: 'rgba(245, 245, 245, 0.95)',
            '&:hover': {
              bgcolor: 'rgba(230, 230, 230, 1)',
            },
            width: 40,
            height: 40,
            borderRadius: '50%',
            boxShadow: 1,
          }}
        >
          <FullscreenIcon sx={{ color: 'rgba(100, 100, 100, 0.8)' }} />
        </IconButton>
      </Box>

      {/* Navigation Arrows */}
      <IconButton
        onClick={handlePrevious}
        sx={{
          position: 'absolute',
          bottom: 16,
          left: 16,
          bgcolor: 'rgba(245, 245, 245, 0.95)',
          '&:hover': {
            bgcolor: 'rgba(230, 230, 230, 1)',
          },
          width: 40,
          height: 40,
          borderRadius: '50%',
          boxShadow: 1,
        }}
      >
        <ChevronLeftIcon sx={{ color: 'rgba(100, 100, 100, 0.8)' }} />
      </IconButton>
      <IconButton
        onClick={handleNext}
        sx={{
          position: 'absolute',
          bottom: 16,
          right: 16,
          bgcolor: 'rgba(245, 245, 245, 0.95)',
          '&:hover': {
            bgcolor: 'rgba(230, 230, 230, 1)',
          },
          width: 40,
          height: 40,
          borderRadius: '50%',
          boxShadow: 1,
        }}
      >
        <ChevronRightIcon sx={{ color: 'rgba(100, 100, 100, 0.8)' }} />
      </IconButton>

      {/* Dot Indicators with Progress Bar */}
      <Paper
        elevation={1}
        sx={{
          position: 'absolute',
          bottom: 16,
          left: '50%',
          transform: 'translateX(-50%)',
          bgcolor: 'rgba(245, 245, 245, 0.95)',
          display: 'flex',
          gap: 1,
          p: 1,
          borderRadius: 4,
          alignItems: 'center',
          maxWidth: '80%',
        }}
      >
        {/* Display starting ellipsis if needed */}
        {showStartEllipsis && (
          <Box
            sx={{
              width: 8,
              height: 6,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="caption" sx={{ fontSize: '10px', color: 'rgba(100, 100, 100, 0.8)' }}>
              •••
            </Typography>
          </Box>
        )}
        
        {/* Display visible dots */}
        {visibleDots.map((dotIndex) => (
          <Box
            key={dotIndex}
            onClick={() => handleDotClick(dotIndex)}
            sx={{
              position: 'relative',
              width: currentIndex === dotIndex ? 14.4 : 6,
              height: 6,
              bgcolor: currentIndex === dotIndex
                ? 'rgba(100, 100, 100, 0.2)'
                : 'rgba(180, 180, 180, 0.7)',
              borderRadius: 3,
              cursor: 'pointer',
              overflow: 'hidden',
              '&:hover': {
                bgcolor:
                  currentIndex === dotIndex
                    ? 'rgba(100, 100, 100, 0.3)'
                    : 'rgba(160, 160, 160, 0.8)',
              },
            }}
          >
            {currentIndex === dotIndex && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: `${progress}%`,
                  bgcolor: 'rgba(50, 50, 50, 0.7)',
                  transition: 'width 0.1s linear',
                }}
              />
            )}
          </Box>
        ))}
        
        {/* Display ending ellipsis if needed */}
        {showEndEllipsis && (
          <Box
            sx={{
              width: 8,
              height: 6,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="caption" sx={{ fontSize: '10px', color: 'rgba(100, 100, 100, 0.8)' }}>
              •••
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default ProductCarousel;