import React, { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Button, CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const ImageDownloader = ({ imageUrls = [],listId }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState(0);

  const fetchImage = async (url) => {
    try {
      // Try to fetch with credentials first
      const response = await fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      console.log(`Successfully fetched image: ${url}`, blob);
      return blob;
    } catch (error) {
      console.error(`Failed to fetch image ${url}:`, error);
      
      // Fallback to img tag approach
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          
          canvas.toBlob((blob) => {
            if (blob) {
              console.log(`Successfully converted image to blob: ${url}`, blob);
              resolve(blob);
            } else {
              reject(new Error('Failed to convert image to blob'));
            }
          }, 'image/jpeg', 0.95);
        };
        
        img.onerror = () => {
          reject(new Error(`Failed to load image: ${url}`));
        };
        
        img.src = url;
      });
    }
  };

  const downloadImages = async () => {
    if (!imageUrls.length) {
      console.error('No image URLs provided');
      return;
    }

    try {
      setIsDownloading(true);
      setProgress(0);
      console.log('Starting download process for images:', imageUrls);

      const zip = new JSZip();
      const folder = zip.folder("images");

      let successCount = 0;
      const totalImages = imageUrls.length;

      for (let i = 0; i < imageUrls.length; i++) {
        const url = imageUrls[i];
        try {
          console.log(`Processing image ${i + 1}/${totalImages}: ${url}`);
          const imageBlob = await fetchImage(url);
          
          if (imageBlob) {
            const extension = url.split('.').pop()?.toLowerCase() || 'jpg';
            const filename = `${listId}_image_${i + 1}.${extension}`;
            folder.file(filename, imageBlob);
            successCount++;
            console.log(`Successfully added ${filename} to zip`);
          }
        } catch (error) {
          console.error(`Failed to process image ${url}:`, error);
        }
        
        setProgress(((i + 1) / totalImages) * 100);
      }

      if (successCount === 0) {
        throw new Error('No images were successfully processed');
      }

      console.log(`Generating zip file with ${successCount} images`);
      const content = await zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
          level: 6
        }
      });

      console.log('Zip file generated, initiating download');
      saveAs(content, "images.zip");
      console.log('Download initiated');
    } catch (error) {
      console.error("Error in download process:", error);
    } finally {
      setIsDownloading(false);
      setProgress(0);
    }
  };

  if (!imageUrls.length) {
    return null;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={downloadImages}
      disabled={isDownloading}
      startIcon={isDownloading ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
      sx={{
        m: 2,
        backgroundColor: '#1976d2',
        '&:hover': {
          backgroundColor: '#1565c0'
        },
        minWidth: 200
      }}
    >
      {isDownloading 
        ? `Processing ${Math.round(progress)}%` 
        : `Download All Images (${imageUrls.length})`}
    </Button>
  );
};

export default ImageDownloader;