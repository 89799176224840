import React, { useState, useEffect } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Container
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

const ModernSearch = () => {
  const navigate = useNavigate()
  

  //test only
  const datatmp = {
    "models": [
      {
        "id": 1,
        "name": "Gamma",
        "description": "Description for Model One",
        "status": "active"
      },
      {
        "id": 2,
        "name": "DI Wafer Cleaner",
        "description": "Description for Model Two",
        "status": "active"
      },
      {
        "id": 3,
        "name": "Model Three",
        "description": "Description for Model Three",
        "status": "active"
      }
    ],
    
    "makes": [
      {
        "id": 1,
        "name": "SSEC",
        "slug": "cat-one"
      },
      {
        "id": 2,
        "name": "Microscope",
        "slug": "cat-2"
      }
    ]
  }
  const [state, setState] = useState({
    searchTerm: "",
    results: {
      models: [],
      categories: []
    },
    isLoading: false,
    showResults: false
  });

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        setState(prev => ({
          ...prev,
          showResults: false,
          searchTerm: ''
        }));
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  useEffect(() => {
    if (state.searchTerm.trim()) {
      const delay = setTimeout(() => {
        fetchResults();
      }, 300);

      return () => clearTimeout(delay);
    } else {
      setState(prev => ({
        ...prev,
        results: { models: [], categories: [] },
        showResults: false
      }));
    }
  }, [state.searchTerm]);

  const fetchResults = async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      const response = await axios.get(`/api/ads/search/${state.searchTerm}`);
      setState(prev => ({
        ...prev,
        results: response?.data || datatmp,
        isLoading: false,
        showResults: true
      }));
    } catch (error) {
      console.error('Search error:', error);
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleInputChange = (e) => {
    setState(prev => ({
      ...prev,
      searchTerm: e.target.value,
      showResults: true
    }));
  };

  const gotoResult = (name) =>{
    //  navigate(`/search?type=category&key=${String(name)}`)
     setState(prev => ({
      ...prev,
      showResults: false
    }));
    window.open(`/search?type=category&key=${String(name)}`, "_blank");

  }

  const gotoResultForModel = (name) =>{
    //  navigate(`/search?type=category&key=${String(name)}`)
     setState(prev => ({
      ...prev,
      showResults: false
    }));
    window.open(`/search?type=model&key=${String(name)}`, "_blank");

  }

  function goToSearchResult() {
    // setState(draft => {
    //   draft.show = "neither"
    // })
    state.searchTerm?navigate(`/searchResult/${state.searchTerm}`):navigate("/searchResult")
    
  }

  const clearSearch = () => {
    setState(prev => ({
      ...prev,
      searchTerm: '',
      showResults: false,
      results: { models: [], categories: [] }
    }));
  };

  const hasResults = state.results.models?.length > 0 || state.results.categories?.length > 0;
  const resultsRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setState(prev => ({
          ...prev,
          
          showResults: false
        }));
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      <Box sx={{ position: 'relative', maxWidth: '800px', mx: 'auto' }}>
        <TextField
          fullWidth
          value={state.searchTerm}
          onChange={handleInputChange}
          placeholder="What are you looking for?"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
  <SearchIcon 
    color="action"
    sx={{
      position: 'absolute',
      top: '50%',
      left: '90% !important', // Using !important to override
      transform: 'translate(-50%, -50%)'
    }}
    onClick={
      goToSearchResult
    }
  />
</InputAdornment>
            ),
            endAdornment: state.searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  onClick={clearSearch}
                  size="small"
                  aria-label="clear search"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              borderRadius: '28px',
              '& fieldset': {
                borderRadius: '28px',
              },
              height: '50px',
            }
          }}
        />

        {state.showResults && (
          <Paper
            elevation={3}
            sx={{
              position: 'absolute',
              top: '100%',
              left: 0,
              right: 0,
              mt: 1,
              maxHeight: '80vh',
              overflow: 'auto',
              zIndex: 1000
            }}
            ref={resultsRef}
          >
            {state.isLoading ? (
              <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Box>
                {/* Models Section */}
                {state.results.models?.length > 0 && (
                  <>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        px: 3,
                        pt: 2,
                        pb: 1,
                        color: '#666',
                        fontWeight: 400,
                        fontSize: '0.9rem',
                        letterSpacing: '0.5px'
                      }}
                    >
                      MODELS
                    </Typography>
                    <List sx={{ p: 0 }}>
                      {state.results.models.map((model, index) => (
                        <ListItem
                          key={`model-${index}`}
                          sx={{
                            px: 3,
                            py: 0.5,  // Reduced vertical padding
                            minHeight: 'auto',  // Allow items to be more compact
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography 
                                sx={{ 
                                  fontSize: '0.875rem',  // Smaller font size for sub-items
                                  color: '#333',
                                  fontWeight: 400,  // Lighter weight for sub-items
                                  pl: 2,
                                  lineHeight: 1.5  // Tighter line height
                                }}
                                onClick={() => 
                                  gotoResultForModel(model.name)
                                
                                }
                              >
                                {model.make +" "+ model.name}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}

                {/* Categories Section */}
                {state.results.categories?.length > 0 && (
                  <>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        px: 3,
                        pt: 2,
                        pb: 1,
                        color: '#666',
                        fontWeight: 400,
                        fontSize: '0.9rem',
                        letterSpacing: '0.5px'
                      }}
                      

                    >
                      CATEGORIES
                    </Typography>
                    <List sx={{ p: 0 }}>
                      {state.results.categories.map((category, index) => (
                        <ListItem
                          key={`category-${index}`}
                          sx={{
                            px: 3,
                            py: 0.5,  // Reduced vertical padding
                            minHeight: 'auto',  // Allow items to be more compact
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography 
                                sx={{ 
                                  fontSize: '0.875rem',  // Smaller font size for sub-items
                                  color: '#333',
                                  fontWeight: 400,  // Lighter weight for sub-items
                                  pl: 2,
                                  lineHeight: 1.5  // Tighter line height
                                }}
                                onClick={() => 
                                  gotoResult(category.name)
                                
                                  
                              
                                }

                              >
                                {category.name}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}

                {/* No Results Message */}
                {!hasResults && !state.isLoading && (
                  <ListItem>
                    <ListItemText
                      primary={`No results found for "${state.searchTerm}"`}
                      sx={{ textAlign: 'center', color: 'text.secondary' }}
                    />
                  </ListItem>
                )}
              </Box>
            )}
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default ModernSearch;